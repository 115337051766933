/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useRef, useState } from 'react';
import { Image } from "react-datocms";

export const ScrollableImage = ({ images }) => {
    const [positionInViewPort, setPositionInViewPort] = useState(100);
    const ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const element: any = ref.current;
            if (element) {
                const rect = element.getBoundingClientRect();
                const elementTop = rect.top + window.scrollY;
                const elementBottom = elementTop + element.offsetHeight;
                const viewportTop = window.scrollY;
                const position = (elementBottom - viewportTop) / (elementBottom - elementTop);
                const percentage = Math.round((position * 100)) - 100;
                if (percentage < 50)
                    return;
                setPositionInViewPort(percentage);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const positionFromTop = 200 - positionInViewPort;
    const positionFromBottom = positionInViewPort;
    return (<>
        <div className="interactive-image" ref={ref}>
            {
                images.map((image, i) => <div key={i} ref={ref} className={`interactive-image__image`} >
                    <Image  usePlaceholder={false} data={image} />
                </div>)
            }
        </div>
        <style jsx>{`
            .interactive-image {
                position: relative;
                width: 100%;
                padding: 50% 0;
            }

            .interactive-image__image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: ${positionFromBottom > 50 ? (positionFromTop * 2 )- 10 : (positionFromBottom * 2) + 50}%;
                transform: translateY(${(50 * positionInViewPort * 0.01 * 2) - 110}px);
            }

            .interactive-image__image:nth-child(2) {
                transform: translateY(${(-50 * positionInViewPort * 0.01 * 2) - 0}px);
                z-index: -1;
            }

            @media (max-width: 768px) {
                .interactive-image__image {
                    opacity: 1;
                    transform: translateY(0);
                }
                .interactive-image__image:nth-child(2) {
                    transform: translateY(0);
                }
            }

        `}</style></>);
};
 