/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "../Icon";
import { Image } from "react-datocms";
import { ScrollableImage } from "../ScrollableImage";
import { GradientDividerLeft } from "../GradientDivider";

export const FeaturesWrapper = ({ children, dark = false, minHeight = "50vh", padding = "var(--landing-page-padding)", alignment = "center" }) => {
    return <>
        <div className={"features " + (dark && "dark")}>
            <div className="features__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .features {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: ${alignment};
                width: 100vw;
                min-height: ${minHeight};
                background: var(--off-white);
            }
            .features.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
                position: relative;
            }
            :global(.features.dark .features__card) {
                background-color: var(--covalent-dark-blue);
            }
            :global(.features.dark .features__card) {
                background-color: var(--covalent-dark-blue);
                border: 1px solid var(--black-light);
                transition: all 0.2s ease-in-out;
                padding: var(--landing-page-gap);
                padding-bottom: var(--landing-page-gap);
                border-radius: var(--border-radius);
            }
            :global(.features.dark .features__card:hover) {
                border: 1px solid var(--code-border);
                transition: all 0.2s ease-in-out;
            }

            :global(.features.dark .features__card) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
                flex: 1 1 calc(25% - 1.5rem);
                min-width: 200px;
                padding: 2rem;
                border-radius: calc(var(--border-radius) * 2);
                background-color: black;
                transform: translate(0);
                transform-style: preserve-3d;
                z-index: 1;
                position: relative;
                border: 1px solid var(--black-light);
                transition: all 0.2s ease-in-out;
                height: auto;
            }

            :global(.features.dark .features__card:hover) {
                border: 1px solid var(--teal);
                transition: all 0.2s ease-in-out;
            }

            @keyframes backgroundGradient {
                0% {
                    background-position: 0% 50%;
                    transform: scale(1.01);
                }
                100% {
                    background-position: 100% 50%;
                    transform: scale(0.98);
                }
            }

            .features__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                padding: ${padding};
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            :global(.dark .features__card .features__card__description) {
                color: var(--text);
            }

            @media (max-width: 768px) {
                .features {
                    flex-direction: column;
                    height: auto;
                }
                .features__content {
                    width: 100%;
                    height: auto;
                    padding: 0rem;
                    padding-bottom: 1rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureTitle = ({ children }) => {
    return <>
        <h2 className={"feature__title animate-in"}>
            {children}
        </h2>
        <style jsx>
            {`
            h2 {
                font-size: var(--font-size-7);
                margin-bottom: 1rem;
                line-height: 1.2;
            }
            :global(.dark .feature__title) {
                margin-bottom: 3rem;
            }
        `}
        </style>
    </>;
};

export const FeatureHighlights = ({ children, dark = false }) => {
    return <>
        <div className={"feature-highlights " + (dark && "dark")}>
            <div className="feature-highlights__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .feature-highlights {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
            .feature-highlights.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlights__content {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                margin: 0 auto;
                width: 100%;
                flex-wrap: wrap;
                gap: 6rem;
            }
            :global(.feature-highlight:nth-child(even) .feature-highlight__content) {
                flex-direction: row-reverse;
            }
            :global(.dark .features__card .features__card__description) {
                color: var(--code-border);
            }
            :global(.feature-highlight:nth-child(even) .feature-highlight__content .feature-highlight-image) {
                margin-left: 0;
            }
            @media (max-width: 768px) {
                .feature-highlights {
                    flex-direction: column;
                    height: auto;
                }
                .feature-highlights__content {
                    width: 100%;
                    height: auto;
                    gap: 2rem;
                }
                :global(.feature-highlight:nth-child(even) .feature-highlight__content) {
                    flex-direction: row;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlight = ({ children, dark = false, overflow = false }) => {
    return <>
        <div className={"feature-highlight animate-in " + (dark && "dark")}>
            <div className="feature-highlight__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .feature-highlight {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: 25vh;
                width: 100%;
                position: relative;
                background-image: radial-gradient(#00D8D578,var(--covalent-dark-blue));
                border-radius: calc(var(--border-radius) * 5);
                padding: 0 6rem;
                box-shadow: 0px 0px 120px -60px var(--teal);
                border: 1px solid #ffffff1f;
                ${overflow ? "overflow: hidden; height: 450px;" : ""}
            }
            .feature-highlight.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlight__content {
                display: flex;
                flex-wrap: no-wrap;
                flex-direction: row;
                place-items: center;
                height: 100%;
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
                text-align: left;
                gap: 6rem;
                justify-content: space-between;
            }
            @media (max-width: 1000px) {
                .feature-highlight__content {
                    padding: 0 2rem;
                }
            }
            @media (max-width: 768px) {
                .feature-highlight {
                    flex-direction: column;
                    height: auto;
                    margin-top: 0;
                    padding: 1rem;
                    box-shadow: 0px 0px 0px 0px;
                }
                .feature-highlight__content {
                    width: 100%;
                    height: auto;
                    flex-wrap: wrap;
                    padding: 0rem 1rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightTitle = ({ children, dark = false }) => {
    return <>
        <div className={"feature-highlight-title animate-in " + (dark && "dark")}>
            <h2 className="feature-highlight-title__content">
                {children}
            </h2>
        </div>
        <style jsx>
            {`
            .feature-highlight-title {
                font-size: var(--font-size-5);
                padding-left: 2rem;
                margin-bottom: 2rem;
                position: relative;
            }
            .feature-highlight-title:after {
                content:'';
                position:absolute;
                bottom:0;
                top: 0;
                left:0;
                background:var(--teal);
                width: 3px;
                border-radius:10px 10px 10px 10px;
            }
            .feature-highlight-title.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlight-title__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
                font-size: var(--font-size-7);
                margin-bottom: 1rem;
                line-height: 1.2;
            }
            @media (max-width: 768px) {
                .feature-highlight-title {
                    flex-direction: column;
                    height: auto;
                    padding-left: 0rem;
                }
                .feature-highlight-title:after {
                    width: 0;
                }
                .feature-highlight-title__content {
                    width: 100%;
                    height: auto;
                    font-size: var(--font-size-5);
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightSubtitle = ({ children, dark = false }) => {
    return <>
        <div className={"feature-highlight-subtitle animate-in " + (dark && "dark")}>
            <div className="feature-highlight-subtitle__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .feature-highlight-subtitle__content:not(.dark) {
               color: var(--text);
            }
            .feature-highlight-subtitle.dark {
                background-color: var(--covalent-dark-blue);
            }
            p {
                color: var(--code-border);
            }
            .feature-highlight-subtitle__content {
                margin: 0 auto;
                width: 100%;
                // font-size: var(--font-size-5);
                margin-bottom: 0rem;
            }
            @media (max-width: 768px) {
                .feature-highlight-subtitle {
                    flex-direction: column;
                    height: auto;
                }
                .feature-highlight-subtitle__content {
                    width: 100%;
                    height: auto;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightContent = ({ children, dark = false, small = false }) => {

    return <>
        <div className={"feature-highlight-content animate-in " + (dark && "dark") + (small && " small")}>
            {children}
        </div>
        <style jsx>
            {`
            .feature-highlight-content.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlight-content {
                width: 50%;
                max-width: 600px;
                padding: 6rem 0rem;
            }
            .feature-highlight-content.small {
                max-width: 33%;
            }
            @media (max-width: 768px) {
                .feature-highlight-content {
                    max-width: 100%;
                    width: 100%;
                    padding: 1rem 0rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightLink = ({ children }) => {
    return <>
        <div className="feature-highlight-link animate-in">
            {children}
        </div>
        <style jsx>
            {`
            .feature-highlight-link.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlight-link {
                color: var(--teal);
                margin-top: 1.7rem;

            }
        `}
        </style>
    </>;
};

export const FeatureHighlightImage = ({ src, dark = false }) => {
    return <>
        <div className={"feature-highlight-image animate-in " + (dark && "dark")}>
            <Image  usePlaceholder={false} data={src} className="" />
        </div>
        <style jsx>
            {`
            .feature-highlight-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                max-width: 500px;
                margin: 0;
                flex: 1;
                min-width: 250px;
                margin-bottom: -3rem;
                margin-top: -3rem;
                z-index: 2;
                filter: drop-shadow(0px 0px 102px #00D8D557);
            }
            @media (max-width: 768px) {
                .feature-highlight-image {
                    margin-left: 0;
                    min-width: 0;
                    margin-bottom: 1rem;
                    margin-top: -2rem;
                }
            }
        `}
        </style>
    </>;
};
export const FeatureHighlightImageNonDato = ({ src, dark = false }) => {
    return <>
        <div className={"feature-highlight-image " + (dark && "dark") +  " isVisibleImage "}>
            <img src={src} className="img" alt="highlight"/>
        </div>
        <style jsx>
            {`
            .feature-highlight-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 650px;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                opacity: 0;
                z-index: 2;
                filter: drop-shadow(0px 0px 102px #00D8D557);
            }
            .img {
                max-width: none;
                height: 600px;
            }
            :global(.feature-highlight .isVisibleImage) {
                animation: fadeInLeft 0.5s ease-in-out forwards;
            }
            :global(.feature-highlight:nth-child(odd) .isVisibleImage) {
                animation: fadeInRight 0.5s ease-in-out forwards;
            }
            @media (max-width: 768px) {
                .feature-highlight-image {
                    margin-left: 0;
                    min-width: 0;
                    margin-bottom: 1rem;
                    margin-top: -2rem;
                    max-height: 200px;
                    display: none;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightImageBackground = ({ src }) => {

    return <>
        <img src={src} className="feature-highlight-image" alt="feature-image"/>
        <GradientDividerLeft/>
        <style jsx>
            {`
            .feature-highlight-image {
                height: 100%;
                width: 100%;
                z-index: 0;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                object-fit: cover;
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightInteractiveImage = ({ images, dark = false }) => {

    return <>
        <div className={"feature-highlight-image " + (dark && "dark") + " isVisibleImage "}>
            <ScrollableImage images={images}/>
        </div>
        <style jsx>
            {`
            .feature-highlight-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                margin-left: auto;
            }
            :global(.feature-highlight .isVisibleImage) {
                animation: fadeInLeft 0.5s ease-in-out forwards;
            }
            :global(.feature-highlight:nth-child(odd) .isVisibleImage) {
                animation: fadeInRight 0.5s ease-in-out forwards;
            }
            @media (max-width: 768px) {
                .feature-highlight-image {
                    margin-left: 0;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightVideo = ({ src, dark = false }) => {
    return <>
        <div className={"feature-highlight-video " + (dark && "dark")}>
            <video src={src} className="" autoPlay muted loop />
            <div className="fade"/>
        </div>
        <style jsx>
            {`
            .feature-highlight-video {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                max-width: 1000px;
                margin-left: auto;
                position: relative;
            }
            video {
                width: 100%;

            }
            .fade {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                box-shadow: 0px 0 80px 40px inset var(--covalent-dark-blue);
                z-index: 1;
            }
            @media (max-width: 768px) {
                .feature-highlight-video {
                    margin-left: 0;
                }
            }
        `}
        </style>
    </>;
};

export const FeaturesCard = ({ children, dark = false, colSpan = 1, animation = true }) => {

    return <>
        <div className={(!dark && "gradient-border ") +  " features__card animate-in " + (!animation && " disable_animation")} >
            {children}
        </div>
        <style jsx>
            {`
            div {
                display: flex;
                grid-column: span 1 / span 1;
                flex-direction: column;
                justify-content: start;
                align-items: flex-start;
                width: 100%;
                gap: var(--landing-page-gap);
                border: 1px solid var(--code-border);
                padding: var(--landing-page-gap);
                border-radius: calc(var(--border-radius) * 1);
                height: auto;
                position: relative;
                background: #fff;
                cursor: default;
                flex: 1;
                text-decoration:none;
                grid-column: span ${colSpan};
            }

            div:hover {
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
            }

            .gradient-border:hover{
                background: var(--background);
                border: 1px solid transparent;
                background-clip: padding-box;
                transition: all 0.25s ease-in-out;
                position: relative;

            }
            :global(.gradient-border:hover .feature-icon){
                transition: all 0.25s ease-in-out;
                opacity:100;
            }
            :global(.features__card:hover .feature-icon){
                transition: all 0.25s ease-in-out;
                opacity:100;
            }

            .gradient-border {
                transition: all 0.25s ease-in-out;
            }

            @keyframes animatedBackground {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
            }

            @media (max-width: 768px) {
                div {
                    flex-direction: column;
                    place-items: start;
                    min-width:0;
                    grid-column: span 1;
                }
            }
        `}
        </style></>;
};


export const FeaturesCards = ({ children, grid = 3 }) => {
    return <>
        <div className="features__cards">
            {children}
        </div>
        <style jsx>
            {`
            div {
                display: grid;  
                grid-template-columns: repeat(${grid}, minmax(0, 1fr));
                gap: var(--landing-page-gap);
                width: 100%;
            }
            @media (max-width: 1250px) {
                div {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
            @media (max-width: 768px) {
                div {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }
        `}
        </style>
    </>;
};

export const FeaturesCardCTA = ({ children }) => {
    return <>
        <div>
            {children}
        </div>
        <style jsx>
            {`
            div {
                margin-top: auto;
            }
        `}
        </style>
    </>;
};

export const FeaturesCardTitle = ({ children, link = "" }) => {
    return <>
        <div className="title">
            <h3>{children}</h3>
            {(link && <div className="feature-icon flex-center">
                <Icon icon={"chevron-right"} color="var(--teal)" />
            </div>)}
        </div>
        <style jsx>
            {`
            .title{
                display:flex;
                align-items:center;
                gap: 0.5rem;
                justify-content:space-between;
                width:100%;
                color: var(--teal);
            }
            .feature-icon{
                opacity: 0;
                transition: all 0.25s ease-in-out;
            }
            h3 {
                font-size: var(--font-size-5);
                margin-bottom: 1rem;
                margin-top: 0;
                margin: 0;
            }
        `}
        </style>
    </>;
};

export const FeaturesCardDescription = ({ children }) => {
    return <>
        <p className="features__card__description">
            {children}
        </p>
        <style jsx>
            {`

            .features__card__description {
                color: var(--text);
            }
        `}
        </style>
    </>;
};